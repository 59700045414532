<template>
  <layout-vertical>

    <router-view :key="$route.fullPath" />

    <div slot="breadcrumb">
      <app-breadcrumbs />
    </div>

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <div slot="footer">
      <p class="mb-0">
        <span class="float-md-left d-block d-md-inline-block mt-25">
          &copy; {{ new Date().getFullYear() }}
          <b-link
            class="ml-25"
            href="https://orangepanda.ru"
            target="_blank"
          >OrangePanda</b-link>
          <span class="d-none d-sm-inline-block">.</span>
        </span>
      </p>
    </div>

    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import { BLink } from 'bootstrap-vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import Navbar from '../components/Navbar.vue'
import AppBreadcrumbs from '../components/AppBreadcrumb.vue'

export default {
  components: {
    // AppCustomizer,
    BLink,
    LayoutVertical,
    Navbar,
    AppBreadcrumbs,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
